var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-card',{staticStyle:{"margin-left":"1%","margin-right":"1%","margin-top":"2%","font-family":"'Montserrat' sans-serif","border-radius":"10px 10px 10px 10px"},attrs:{"max-width":"98%","max-height":"15000"}},[_c('div',[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_c('v-spacer'),_c('v-spacer'),_c('b',[_vm._v("Cuentas por pagar")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-sheet',[_c('div',{staticStyle:{"position":"relative","float":"right"}},[_c('router-link',{staticClass:"link",attrs:{"to":"#"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"green"}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" AGREGAR BANCO")])],1)],1),_c('div',{staticStyle:{"position":"relative","float":"left"}},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.cuentas)+" cuentas por pagar /")]),_vm._v(" "+_vm._s(_vm.page)+" páginas")])])])],1),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"clave","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Single select"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true},{key:"item.clave",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":"/detalles"}},[_c('br'),_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#818181","font-size":"14px","border":"1px solid #818181","border-radius":"100px"},attrs:{"small":""}},[_vm._v(" fas fa-ellipsis-h ")]),_c('span',[_vm._v(_vm._s(item.clave))]),_c('br'),_c('br')],1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.total)))])]}},{key:"item.pagar",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.pagar)))])]}},{key:"item.operadora",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.operadora)))])]}},{key:"item.hotel",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.hotel))])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.admin))])]}},{key:"item.agencia",fn:function(ref){
var item = ref.item;
return [(item.img)?_c('img',{staticStyle:{"width":"18px"},attrs:{"src":item.img}}):_vm._e(),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(item.agencia))])]}},{key:"item.entrada",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.entrada)))])]}},{key:"item.salida",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.salida)))])]}},{key:"item.fecha_limite",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.fecha_limite)))])]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.cliente))])]}},{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-size":"13px"},attrs:{"color":_vm.getEstatus(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+" ")])]}},{key:"item.usuario",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.usuario)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)]),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }